import { autoinject } from 'aurelia-framework';
import { PermissionService } from 'resources/services/PermissionService';
import {Router} from "aurelia-router";

@autoinject
export class LoginRbac {
    private router: Router;
    private permissionService: PermissionService;
    private selectedRoleId: any;

    constructor(router: Router, permissionService: PermissionService) {
        this.router = router;
        this.permissionService = permissionService;
    }

    get roles() {
        return this.permissionService.getUserRoles();
    }

    attached() {
        this.selectedRoleId = this.permissionService.activeUserRole?.id;

        if (!this.selectedRoleId) {
            this.selectedRoleId = this.roles[0]?.id;
        }
    }

    submit() {
        if (!this.selectedRoleId) {
            return;
        }

        this.permissionService.assignUserRole(this.selectedRoleId);

        this.router.navigateBack();
    }
}
